import { classnames } from "@lib/tools/helpers";
import tablecss from "@styles/components/Table.module.scss";
import React from "react";
const Table = ({
  head,
  hidden,
  body,
  width = "100%",
  children
}: {
  head: React.ReactNode;
  hidden: boolean;
  body: React.ReactNode;
  width?: string;
  children?: React.ReactNode;
}) => {
  return <div data-hidden={hidden} className={tablecss["table"]} style={{
    "--width": width
  } as any} data-sentry-component="Table" data-sentry-source-file="Table.tsx">
      <div {...classnames(tablecss, "row", "head")}>{head}</div>

      {children || body || null}
    </div>;
};
export default Table;