import AlertIcon from "@assets/icons/general/AlertIcon";
import ErrorIcon from "@assets/icons/general/ErrorIcon";
import Logo from "@assets/icons/general/Logo";
import PlayIcon from "@assets/icons/general/play-icon";
import { WorldLockIcon } from "@assets/icons/general/WorldLockIcon";
import FullFrameIcon from "@assets/icons/iframe/FullFrameIcon";
import KeyholeIcon from "@assets/icons/iframe/KeyholeIcon";
import TheatherModeIcon from "@assets/icons/iframe/TheatherModeIcon";
import useLanguage, { Dict } from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import usePopup from "@hooks/usePopup";
import useTheaterMode from "@hooks/useTheaterMode";
import { useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import Button from "@items/Button";
import Toggle from "@items/Toggle";
import ToolTip from "@items/ToolTip";
import { cn, sanitizeUrl } from "@lib/utils";
import { NODE_API } from "@lib/api/api";
import StorageService from "@lib/services/Storage.Service";
import { classnames, handleCatchPopup, isMobileUserAgent, mergeClasses, toggleFullScreen, useLocale } from "@lib/tools/helpers";
import { logError } from "@lib/tools/logger";
import st from "@styles/pages/Slots.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
import Script from "next/script";
import { memo, startTransition, useEffect, useMemo, useRef, useState } from "react";
import useSWR, { unstable_serialize } from "swr";
import SlotsList from "./slots-list";
import GameDescription from "@Games/GameDescription";
import Accordion from "@components/Accordion/v2";
type ProviderGamesDisplayProps = {
  gameDetails: any;
  gameContent: any;
  type?: string;
};
const ProviderGamesDisplay = ({
  gameDetails,
  gameContent,
  type
}: ProviderGamesDisplayProps) => {
  const L = useLanguage(["common", "Slots"]);
  const [isScriptLoaded, setIsScriptLoaded] = useState(() => {
    if (typeof window === "undefined") return false;
    return "sg" in window;
  });
  const [overlay, setOverlay] = useState(false);
  const router = useRouter();
  const {
    userChecked,
    hasUserData,
    userData
  } = useUserSession();
  const {
    activeBalance
  } = useWallet();
  const lang = useLocale();
  const [demo, setDemo] = useState(!hasUserData);
  const [mobileFullScreen, setMobileFullScreen] = useState(undefined);
  const scrollToTop = () => {
    document?.querySelector(".body-content-container")?.scrollTo(0, 0);
  };
  useEffect(() => {
    const value = StorageService.getLocalStorageValue("slots-mobile-fullscreen");
    if (value !== "undefined") {
      setMobileFullScreen(value);
    } else {
      setMobileFullScreen(true);
    }
  }, []);
  useEffect(() => {
    if (mobileFullScreen !== undefined) {
      StorageService.setLocalStorageValue("slots-mobile-fullscreen", mobileFullScreen);
    }
  }, [mobileFullScreen]);
  const setMessage = usePopup();
  const gameInstanceRef = useRef(null);
  const {
    setInPlay,
    userBalanceType,
    setUserBalanceType,
    walletSetting,
    balanceMutate
  } = useWallet();
  const {
    theaterMode,
    toggleTheaterMode,
    slotGameContainerStyle,
    aspectRatioStyle,
    removeMaxWidth
  } = useTheaterMode(type);
  const isViewMobile = useMobile({
    breakPointOne: 1006,
    breakPointTwo: 768,
    breakPointThree: 680
  });
  const [aspectRatio] = useState([55, 31]);
  const [errorDisplay, setErrorDisplay] = useState<string | null>(null);
  const game_url = router?.query?.game || router?.query?.slug;
  const mobile_real_demo_setting = router?.query?.play;

  // Add validation for game_url
  useEffect(() => {
    if (game_url === "undefined" || !game_url) {
      router.push("/casino"); // Redirect to casino page if game_url is invalid
      return;
    }
  }, [game_url, router]);
  const game = useSWR(
  // Only make the request if game_url is valid
  game_url && game_url !== "undefined" ? ["v1/slots/details", game_url] : null, ([url, game_url]) => NODE_API.post(url, {
    game_url,
    type
  }).then(res => {
    if (res.status !== 200) {
      return null;
    }
    return res.data;
  }).catch(e => {
    handleCatchPopup(setMessage)(e);
    // Add additional error handling
    if (e?.response?.status === 404) {
      router.push("/casino");
    }
    return null;
  }), {
    fallback: {
      [unstable_serialize(["v1/slots/details", game_url])]: gameDetails
    },
    revalidateOnFocus: false,
    revalidateOnMount: !!gameDetails
  });
  const metaGameInfo = game.data || gameDetails;
  const promoAllowed = game.data?.promo_balance_eligible || false;

  // Live games not allowed demo if guest, If logged in and no balance, allow live game but cant do anything
  const blockLiveGameDemo = game.data?.type === "live";
  useEffect(() => {
    if (typeof window === "undefined") return;
    const setAspectRatio = () => {
      const paddingBottom = aspectRatio[1] / aspectRatio[0] * 100;
      document.documentElement.style.setProperty("--aspectratio", `${paddingBottom}%`);
    };
    setAspectRatio();
    window.addEventListener("resize", setAspectRatio);
    return () => window.removeEventListener("resize", setAspectRatio);
  }, [aspectRatio]);
  const sanitizeSpecificStyles = iframe => {
    if (!iframe) return;
    const styles = iframe?.style;

    // Remove unwanted styles
    if (styles?.left === "-10000px") {
      styles.removeProperty("left");
    }
    if (styles?.position === "absolute") {
      styles.removeProperty("position");
    }
  };
  const mode = useMemo(() => {
    const localDemo = blockLiveGameDemo && userData?.username ? false : demo;
    if (isViewMobile) {
      if (!mobile_real_demo_setting) {
        return null;
      }
      return mobile_real_demo_setting === "fun" ? "demo" : "realplay";
    }
    return localDemo ? "demo" : "realplay";
  }, [blockLiveGameDemo, userData?.username, demo, isViewMobile, mobile_real_demo_setting]);
  const slot = useMemo(() => {
    if (type === "original") {
      return null;
    }

    //Prevent launch if promo not allowed and promo balance is selected
    if (!promoAllowed && userBalanceType !== false) {
      return false;
    }
    const promoBalance = promoAllowed ? userBalanceType : false;
    if (isViewMobile) {
      if (!mobile_real_demo_setting) {
        gameInstanceRef.current = null;
        let iframe;
        if (typeof window !== "undefined") {
          iframe = document.getElementById("game_wrapper")?.querySelector("iframe");
          if (iframe) {
            iframe.remove();
          }
        }
        return false;
      }
    }
    return [game_url, lang, promoBalance, walletSetting, mode];
  }, [game_url, isViewMobile, lang, mobile_real_demo_setting, mode, promoAllowed, type, userBalanceType, walletSetting]);
  const slotSwr = useSWR(slot || null, async ([game_url, language, balance_type, currency, mode]) => {
    try {
      const res = await NODE_API.post(`v1/slots/launch`, {
        device: isMobileUserAgent() ? "mobile" : "desktop",
        mode,
        game_url,
        language,
        balance_type,
        currency
      });
      if (res.status !== 200) {
        throw new Error(res.data?.error || "er_launching_game");
      }
      return res.data;
    } catch (e) {
      if (e?.response?.data?.error) {
        throw new Error(e.response.data.error);
      }
      throw new Error("er_launching_game");
    }
  }, {
    revalidateOnFocus: false,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false // Disable retry on error
  });

  // Clear the cache when slotKey changes
  useEffect(() => {
    if (slot) return;
    slotSwr.mutate(slot, null);
  }, [slot, slotSwr]);
  const launch_url = slotSwr.data?.launch_url;

  // Handle ESC key to remove 'real' param from URL
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && router.query.play) {
        const query = {
          ...router.query
        };
        delete query.play;
        router.replace({
          pathname: router.pathname,
          query
        }, undefined, {
          shallow: true
        });
      }
    };
    window.addEventListener("keydown", handleEscKey);
    return () => window.removeEventListener("keydown", handleEscKey);
  }, [router]);

  // error handlers
  useEffect(() => {
    startTransition(() => {
      if (slotSwr.error?.message) {
        if (slotSwr.error.message === "er_demo_not_available") {
          if (!hasUserData) {
            setOverlay(false);
          }
        }
        if (slotSwr.error.message !== "er_live_game_demo_mode" && slotSwr.error.message !== "er_country_blocked_game" && slotSwr.error.message !== "er_currency_not_allowed") {
          setMessage({
            code: "responses." + slotSwr.error.message,
            type: 0
          });
        }
        return setErrorDisplay(slotSwr.error.message);
      }
      setErrorDisplay(null);
    });
  }, [hasUserData, slotSwr.data, slotSwr.error, setMessage]);
  useEffect(() => {
    if (!userChecked) return;
    startTransition(() => {
      if (hasUserData) setOverlay(true);
      if (blockLiveGameDemo && userData?.username) setDemo(false);
      if (!promoAllowed && userBalanceType !== false) {
        setUserBalanceType(false);
        setMessage({
          code: "responses.no_only_main_balance",
          type: 2
        });
      }
    });
  }, [blockLiveGameDemo, hasUserData, promoAllowed, setMessage, setUserBalanceType, userBalanceType, userChecked, userData?.username]);
  useEffect(() => {
    if (!isScriptLoaded || !launch_url) return;

    // Remove iframe if game failed, only launch the game if the gameInfo has changed
    if (gameInstanceRef.current !== null) {
      // Remove the existing iframe
      const iframe = document.getElementById("game_wrapper").querySelector("iframe");
      if (iframe) {
        iframe.remove();
      }
    }
    const gameLaunchOptions = {
      target_element: "game_wrapper",
      launch_options: {
        game_url: launch_url,
        game_launcher_url: "https://cdn.launcher.a8r.games/index.html",
        strategy: "iframe"
      }
    };
    const success = gameDispatcher => {
      gameInstanceRef.current = gameDispatcher;

      // This is to fix the iframe position and size after the game loads when user has browser extensions enabled
      const iframe = document?.getElementById("game_wrapper")?.querySelector("iframe");
      if (iframe) {
        iframe.onload = () => sanitizeSpecificStyles(iframe);
      }
    };
    const error = error => {
      logError("Slots Error:", error);
    };
    if ("iframeAttributes" in window.sg) {
      const attributes = window.sg.iframeAttributes?.allow;
      window.sg.iframeAttributes.allow = attributes?.filter?.(item => item !== "fullscreen");
    }
    window.sg.launch(gameLaunchOptions, success, error);
  }, [isScriptLoaded, launch_url]);
  useEffect(() => {
    if (type !== "original") {
      setInPlay(true);
    }
    balanceMutate?.();
    return () => {
      balanceMutate().catch(() => {});
      if (type !== "original") {
        setInPlay(false);
      }
    };
  }, [setInPlay, balanceMutate, type]);
  const fullScreen = () => {
    const parentElement = document.getElementById("game_wrapper");
    const iframe = parentElement.querySelector("iframe");
    toggleFullScreen(iframe);
  };
  const refOverlay = useRef(null);

  //Turn off theater mode when leaving slots
  useEffect(() => {
    return () => {
      removeMaxWidth();
    };
  }, [removeMaxWidth]);
  const errorContent = useMemo(() => {
    if (errorDisplay) {
      return <div className={st["error-display"]}>
          <div className={st["error-content"]}>
            {errorDisplay === "er_launching_game" && <>
                <ErrorIcon height={50} width={50} />
                <p> {L("error_launching_game", {})}</p>
                <Button text={L("go_back", {})} method={() => {
              if (window.document.referrer.includes(window.location.origin)) {
                router.back();
              } else {
                router.push("/");
              }
            }} classType={["green"]} />
              </>}

            {errorDisplay === "er_country_blocked_game" && <>
                <WorldLockIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("error_blocked_game", {})}</p>
                <Button text={L("go_back", {})} method={() => {
              if (window.document.referrer.includes(window.location.origin)) {
                router.back();
              } else {
                router.push("/");
              }
            }} classType={["green"]} />
              </>}

            {errorDisplay === "er_promotion_blocked_game" && <>
                <AlertIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("er_promotion_blocked_game", {})}</p>
                <Button text={L("go_back", {})} method={() => {
              if (window.document.referrer.includes(window.location.origin)) {
                router.back();
              } else {
                router.push("/");
              }
            }} classType={["green"]} />
              </>}
            {errorDisplay === "er_live_game_demo_mode" && <>
                <KeyholeIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("er_live_game_demo_mode", {})}</p>
                <div className={st["demo-error-button-container"]}>
                  <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "login"
              }} active={false} text={L("login", {})} classType={["header-btn", "grey-button", "login-button"]} />

                  <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "register"
              }} active={false} text={L("register", {})} classType={["blue", "header-btn"]} />
                </div>
              </>}
            {errorDisplay === "er_currency_not_allowed" && <>
                <ErrorIcon height={50} width={50} />
                <p> {L("er_currency_not_allowed", {})}</p>
                <Button text={L("go_back", {})} method={() => {
              if (window.document.referrer.includes(window.location.origin)) {
                router.back();
              } else {
                router.push("/");
              }
            }} classType={["green"]} />
              </>}
          </div>
        </div>;
    } else {
      return null;
    }
  }, [L, errorDisplay, router]);
  const mobileContentDisplay = useMemo(() => {
    if (isViewMobile && !errorContent && type !== "original") {
      return <div className={st["mobile-option-display"]}>
          {blockLiveGameDemo && !hasUserData ? <div className={st["error-display"]}>
              <div className={st["error-content"]}>
                <KeyholeIcon height={50} width={50} fill={"#7179A5"} />
                <p>{L("er_live_game_demo_mode", {})}</p>
                <div className={st["demo-error-button-container"]}>
                  <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "login"
              }} active={false} text={L("login", {})} classType={["header-btn", "grey-button", "login-button"]} />

                  <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "register"
              }} active={false} text={L("register", {})} classType={["blue", "header-btn"]} />
                </div>
              </div>
            </div> : <>
              <div className={st["mobile-toggles-content"]}>
                {overlay && <div {...classnames(st, "mobile-toggle-container", !hasUserData && "mobile-toggle-container__turn-off")}>
                    <Toggle active={demo} method={() => {
                setDemo(item => !item);
                setOverlay(true);
              }} classType={["gradient-blue"]} />

                    <span>{L("play_in_demo_mode", {})}</span>
                  </div>}

                <div className={st["mobile-toggle-container"]}>
                  <Toggle active={mobileFullScreen} method={() => {
                setMobileFullScreen(item => !item);
              }} classType={["gradient-blue"]} />

                  <span>{L("full_screen", {})}</span>
                </div>
              </div>

              <div className={st["mobile-play-btn-container"]}>
                {!overlay && <div className={st["mobile-register-login-container"]}>
                    <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "login"
              }} active={false} text={L("login", {})} style={{
                backgroundColor: "#3d457c"
              }} classType={["mid-height", "full-width"]} />
                    <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "register"
              }} active={false} text={L("register", {})} classType={["blue", "mid-height", "full-width"]} />
                  </div>}

                <Button buttonType={"link"} text={demo ? L("play_game_in_gemo_mode", {}) : L("play_game", {})} classType={["green", "icon-left", "mid-height"]} isShallow={true} icon={<PlayIcon width={15} height={13} />} url={{
              pathname: router.asPath,
              query: {
                play: demo ? "fun" : "real"
              }
            }} />
              </div>
            </>}
        </div>;
    } else if (isViewMobile && errorContent) {
      return errorContent;
    } else {
      return null;
    }
  }, [L, blockLiveGameDemo, demo, errorContent, hasUserData, isViewMobile, mobileFullScreen, overlay, router.asPath, type]);

  // Add early return if game_url is invalid
  if (!game_url || game_url === "undefined") {
    return <div className={st["error-display"]}>
        <div className={st["error-content"]}>
          <ErrorIcon height={50} width={50} />
          <p>{L("error_invalid_game", {})}</p>
          <Button text={L("go_back", {})} method={() => router.push("/casino")} classType={["green"]} />
        </div>
      </div>;
  }
  return <>
      {type !== "original" && <Script id="provider-slots-script" src={process.env.NEXT_PUBLIC_SS_SCRIPT_URL} onLoad={() => {
      setIsScriptLoaded(true);
    }} />}

      {type !== "original" && <div className={`${st["container"]} rounded-[6px]`}>
          <div {...classnames(st, "game-content")}>
            <div ref={refOverlay} {...mergeClasses(st["overlay"], overlay || errorDisplay ? st["close"] : null)}>
              <div className={st["button-content"]}>
                {hasUserData && userChecked ? <>
                    {Number(activeBalance) > 0 ? null : <>
                        <Button buttonType={"link"} url={{
                  pathname: router.asPath,
                  query: {
                    modal: "wallet",
                    tab: "deposit"
                  }
                }} active={false} text={L("deposit", {})} classType={["green", "deposit"]} />
                        {!metaGameInfo?.live ? <Button text={L("play_demo", {})} method={() => {
                  setOverlay(true);
                  setDemo(true);
                }} /> : <Button text={L("play", {})} method={() => {
                  setOverlay(true);
                  setDemo(false);
                }} />}
                      </>}
                  </> : <>
                    <Button buttonType={"link"} query={{
                modal: "auth",
                tab: "register"
              }} active={false} text={L("register", {})} classType={["green"]} />

                    {/*Live games dont have demo for guests */}
                    {/* NOT LIVE YET******* */}
                    {!metaGameInfo?.live && <Button text={L("play_demo", {})} method={() => {
                setOverlay(true);
                setDemo(true);
              }} />}
                  </>}
              </div>
            </div>
            {/* <div className={st["game-display-container"]}> */}
            <div className={cn(st["aspect-ratio"], "[@media(max-width:999px)]:!h-auto")} style={aspectRatioStyle}>
              {!isViewMobile && !!errorContent && errorContent}

              <div id="game_wrapper" {...classnames(st, "slot-game-container", isViewMobile && "mobile-view", !errorContent && isViewMobile && mobile_real_demo_setting === "fun" || !errorContent && isViewMobile && mobile_real_demo_setting === "real" ? "active-mobile-view" : null, isViewMobile && mobileFullScreen && "full-screen")} style={!isViewMobile ? slotGameContainerStyle : null} />
            </div>
            <div className={cn(st["slot-game-bar"], "[@media(min-width:999px)]:flex hidden")} style={{
          borderBottomRightRadius: `${theaterMode && type !== "original" ? 0 : 6}px`,
          borderBottomLeftRadius: `${theaterMode && type !== "original" ? 0 : 6}px`
        }}>
              <div className={`${st["slot-game-bar-left-container"]}`}>
                <Logo />
                <div className={st["divider-line"]}></div>
                <div className={`${st["game-info"]}`}>
                  <h2>{metaGameInfo?.title}</h2>
                  <div onClick={scrollToTop}>
                    <Link shallow className={st["game-link-content"]} prefetch={false} href={metaGameInfo?.type === "slots" ? {
                  pathname: `/casino/slots`,
                  query: {
                    provider: metaGameInfo?.producer
                  }
                } : {
                  pathname: sanitizeUrl(`/casino/${metaGameInfo?.type === "live" ? "" : "live"}/${metaGameInfo?.type}`),
                  query: {
                    provider: metaGameInfo?.producer
                  }
                }}>
                      <h3>{metaGameInfo?.producer}</h3>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                        <path d="M0.46967 8.85639C0.176777 9.14928 0.176777 9.62416 0.46967 9.91705C0.762563 10.2099 1.23744 10.2099 1.53033 9.91705L0.46967 8.85639ZM9.52817 1.60854C9.52817 1.19433 9.19239 0.858544 8.77817 0.858544L2.02817 0.858544C1.61396 0.858544 1.27817 1.19433 1.27817 1.60854C1.27817 2.02276 1.61396 2.35854 2.02817 2.35854H8.02817V8.35854C8.02817 8.77276 8.36396 9.10854 8.77817 9.10854C9.19239 9.10854 9.52817 8.77276 9.52817 8.35854L9.52817 1.60854ZM1.53033 9.91705L9.3085 2.13887L8.24784 1.07821L0.46967 8.85639L1.53033 9.91705Z" fill="#E8E5FF" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <div className={`${st["game-options"]}`}>
                <div className={`${st["game-options-buttons"]}`}>
                  <ToolTip text={"Theater Mode"} placement={"top"} size={"small"} transparent={true}>
                    <Button classType={["icon-only", "full-screen", theaterMode && type !== "original" && "theather-active"]} method={() => {
                  toggleTheaterMode();
                }} icon={<TheatherModeIcon />} />
                  </ToolTip>
                  <ToolTip text={L("full_screen", {})} placement={"top"} size="small" transparent={true}>
                    <Button classType={["icon-only", "full-screen"]} method={() => {
                  fullScreen();
                }} icon={<FullFrameIcon />} />
                  </ToolTip>
                </div>

                <div className={` ${st["demo-toggle"]}
                        ${!hasUserData ? st["guest"] : ""}
                        ${blockLiveGameDemo ? st["guest"] : ""}
                      `}>
                  <span className={`${st["real-play-span"]} ${demo && st["demo-mode"]} ${!hasUserData && overlay ? st["real-play-demo"] : ""}`}>
                    {L("fun_play", {})}
                  </span>
                  <Toggle active={!demo} method={() => {
                setDemo(prev => !prev);
                setOverlay(true);
              }} classType={["gradient-blue"]} />

                  <span className={`${demo && st["demo-mode-real"]}`}>
                    {L("real_play", {})}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>}

      <div className={cn(st["game-details-container"], theaterMode && "max-w-[1200px] mx-auto [@media(min-width:999px)]:px-4")}>
        <GameDescription meta={metaGameInfo} gameImage={gameDetails?.custom_banner ? gameDetails?.custom_banner : gameDetails?.icon} gameDetails={gameContent} mobileSlotDisplay={mobileContentDisplay} data-sentry-element="GameDescription" data-sentry-source-file="provider-games-display.tsx" />
      </div>

      {type !== "original" && <>
          <div className={cn(st["games-might-like-container"], "mb-9", theaterMode && "max-w-[1200px] mx-auto [@media(min-width:999px)]:px-4")}>
            <SlotsList sortBy={"random"} heading="games_you_might_like" theme={metaGameInfo?.theme} category={metaGameInfo?.category} isLive={router.pathname.includes("game-shows")} carousel grouping={""} shouldShowQuickLinks={false} providerName={metaGameInfo?.producer} />
          </div>

          {gameContent?.interlinks?.length && <Accordion header={<Dict name="more_games" section="Slots" as="div" />} className={cn(st.accordion, theaterMode && "max-w-[1200px] mx-auto [@media(min-width:999px)]:px-4")} variant="third second padding">
              {gameContent?.interlinks.map(link => <a href={link.url} key={link.url}>
                  {link.name}{" "}
                  {link.provider ? <>
                      <Dict name="by" as="span" section="common" />{" "}
                      {link.provider}
                    </> : null}
                </a>)}
            </Accordion>}
        </>}
    </>;
};
export default memo(ProviderGamesDisplay);