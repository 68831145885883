import st from "../styles/items/Toggle.module.scss";
import { classnames } from "@lib/tools/helpers";
import { forwardRef, useId } from "react";

/**
 * @param {Array<string>} [classType=[""]] - Additional CSS classes to apply.
 * @deprecated
 */

export default function ToggleSelect(props) {
  const {
    active = false,
    method = false,
    dispatch = false,
    name,
    noName,
    classType = []
  } = props;
  const toggledSwitch = () => {
    if (dispatch) {
      dispatch({
        type: "FIELD",
        target: name,
        payload: !active
      });
    } else {
      method(item => !item);
    }
  };
  return (
    //Will probably need an on touch
    <div data-old className={!noName ? st["toggle-content"] : st["toggle-content", "no-name"]} data-sentry-component="ToggleSelect" data-sentry-source-file="Toggle.jsx">
      <span>{name}</span>
      <button form="nonExistentFormId" onClick={() => toggledSwitch()} className={`${st["toggle-switch"]}  ${classType.map(item => st[item]).join(" ")} ${active ? st["active-toggle"] : null}`}>
        <div className={`${st["gradient-item"]}`}></div>
        <div className={st["switch-icon"]}></div>
      </button>
    </div>
  );
}
const defaultType = ["gradient-blue"];
function ToggleNoRef({
  label,
  classType = defaultType,
  onClick,
  ...props
}, fref) {
  const id = useId();
  const labelless = !label;
  return <>
      <label {...classnames(st, "toggle-content", labelless && "no-name")} data-toggle htmlFor={id}>
        {label && <span>{label}</span>}
        <input hidden type="checkbox" id={id} ref={fref} {...props} />

        <div {...classnames(st, "toggle-switch", ...classType)} onClick={onClick}>
          <div className={st["gradient-item"]}></div>
          <div className={st["switch-icon"]}></div>
        </div>
      </label>
    </>;
}
export const Toggle = forwardRef(ToggleNoRef);