import Image from "next/image";
import p1 from "./p1.svg";
import p2 from "./p2.svg";
import p3 from "./p3.svg";
import px from "./p4.svg";
import { memo, useMemo } from "react";
const switchIcon = p => {
  if (p === 1) return p1;
  if (p === 2) return p2;
  if (p === 3) return p3;
  return px;
};
function Icon({
  p = 4,
  width = 28,
  height = 28
}) {
  const img = useMemo(() => switchIcon(+p), [p]);
  const x = img.width * width / 28; // 28 = inner hexagon size
  const y = img.height * height / 28;
  return <div className="inline-block relative pointer-events-none" style={{
    width: x,
    height: y
  }} data-sentry-component="Icon" data-sentry-source-file="Icon.tsx">
      <span className={`absolute w-full h-full z-[1] tabular-nums flex items-center justify-center text-normal `}>
        {p}
      </span>

      {p <= 99 && <Image unoptimized loading="lazy" src={img.src} alt={`An icon representing the ${p}th place.`} width={x + 1} height={y} className="w-full h-full " />}
    </div>;
}
export default memo(Icon);