import Hidden from "@assets/icons/general/Hidden";
import Icon from "@assets/icons/leaderboard/Icon";
import Table from "@components/Table";
import useLanguage from "@hooks/useLanguage";
import useMobile from "@hooks/useMobile";
import useToken from "@hooks/useToken";
import Button from "@items/Button";
import GamesIcons from "@items/games-icons";
import RewardIcons from "@items/RewardIcons";
import ToolTip from "@items/ToolTip";
import ValueDisplay from "@items/ValueDisplay";
import formatNumberLocale from "@lib/tools/format-number-locale";
import { capitalize, classnames, formatDateMonthDayYear, useLocale } from "@lib/tools/helpers";
import { cn } from "@lib/utils";
import st from "@styles/games/GameUi.module.scss";
import DOMPurify from "dompurify";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMemo, useState } from "react";
const TABS = {
  description: "description",
  instructions: "instructions",
  top_wins: "top_wins",
  luckiest_wins: "luckiest_wins"
};
const POSITION_WIDTH = 80;
const Head = () => {
  const L = useLanguage(["common", "bets"]);
  const isMobile = useMobile({} as any);
  const mobile2 = isMobile === "mobile-two";
  return <>
      <div className="max-w-20">
        <div style={{
        width: 58,
        textAlign: "center"
      }}>#</div>
      </div>
      <div style={{
      justifyContent: "flex-start"
    }}>
        {L("user", {})}
      </div>
      {!mobile2 && <div>{L("betAmount", {})}</div>}
      <div>{L("multiplier")}</div>
      <div>{L("payout", {})}</div>
      {!isMobile && <div style={{
      justifyContent: "flex-end",
      paddingRight: 16
    }}>
          {L("date", {})}
        </div>}
    </>;
};
const BodyItem = item => {
  const locale = useLocale();
  const L = useLanguage(["common"]);
  const pathname = usePathname();
  const isMobile = useMobile();
  const mobile2 = isMobile === "mobile-two";
  return <Link shallow replace href={{
    pathname,
    query: {
      modal: "bet",
      tab: "result",
      betId: item.id
    }
  }} data-sentry-element="Link" data-sentry-component="BodyItem" data-sentry-source-file="GameDescription.tsx">
      <div className="max-w-20">
        <Icon p={item.p} data-sentry-element="Icon" data-sentry-source-file="GameDescription.tsx" />
      </div>

      <div className="flex min-w-20 gap-1.5 !justify-start">
        <ToolTip text={item?.rank && `${capitalize(item?.rank)} ${item?.division?.toLocaleUpperCase?.() || ""}`} placement={undefined} transparent={undefined} data-sentry-element="ToolTip" data-sentry-source-file="GameDescription.tsx">
          {item?.rank && <div className="shrink-0">
              <RewardIcons code={item?.rank?.toUpperCase()} size={"xlarge"} />
            </div>}
        </ToolTip>

        {item.username || <span className="text-font-color-seven [&_svg]:opacity-50 flex items-center gap-2">
            {L("hidden", {})}
            <Hidden />
          </span>}
      </div>

      {!mobile2 && <div>
          <ValueDisplay amount={item?.currency_amount} size={"mid"} currencyCode={item?.currency} />
        </div>}
      <div className="text-font-color-seven">
        {formatNumberLocale(item.multiplier, locale)}x
      </div>
      <div className="text-secondary-stroke-color">
        <ValueDisplay amount={item.currency_payout} size={"mid"} currencyCode={item?.currency} data-sentry-element="ValueDisplay" data-sentry-source-file="GameDescription.tsx" />
      </div>
      {!isMobile && <div className="flex !justify-end pr-4 text-font-color-seven">
          {formatDateMonthDayYear(item.timestamp, locale)}
        </div>}
    </Link>;
};
const filterTable = gameDescription => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = gameDescription;

  // Get all <tr> elements from the table inside 'gameDescriptionContent'
  const rows = tempDiv.querySelectorAll("tr");

  // Iterate over each row
  rows.forEach(row => {
    const cells = row.querySelectorAll("td");

    // Iterate through the cells in the row
    for (let i = 0; i < cells.length; i++) {
      const currentCell = cells[i];

      // Check if the current cell has content
      if (currentCell.textContent.trim() !== "") {
        let colspan = 1;

        // Iterate through the subsequent cells
        for (let j = i + 1; j < cells.length; j++) {
          const nextCell = cells[j];
          // Check if the next cell is empty
          if (nextCell.textContent.trim() === "") {
            colspan++; // Increase the colspan value
            nextCell.style.display = "none"; // Hide the empty cell
          } else {
            break; // Stop if the next cell has content
          }
        }
        // Set the updated colspan for the current cell
        if (colspan > 1) {
          currentCell.setAttribute("colspan", String(colspan));
        }
      }
    }
  });
  return tempDiv.innerHTML;
};
const GameNameCount = ({
  meta,
  displayMobileSlotMenu,
  gameDetails
}) => {
  const L = useLanguage(["common", "UserDisplay"]);
  const locale = useLocale();
  const rtp = useMemo(() => formatNumberLocale(meta?.rtp, locale), [locale, meta?.rtp]);
  const count = Number(meta?.statistics?.round_count);
  return <>
      {meta?.type && <div {...classnames(st, "count__type")}>
          <GamesIcons code={meta?.type} size={"small"} />
          <p className="leading-none">{capitalize(meta?.type)}</p>
        </div>}
      {meta?.title && !displayMobileSlotMenu && <p {...classnames(st, "count__title")}>{meta?.title}</p>}

      {displayMobileSlotMenu && <h1 className="text-[13px] mb-5 leading-[1.4]">
          {gameDetails?.heading}
        </h1>}

      {meta?.statistics?.round_count > 0 && <div className="flex flex-col border border-[rgba(124,131,177,.145)] border-solid rounded-lg py-2.5 px-[15px]" style={{
      background: "linear-gradient(180deg,rgba(124,131,177,.05),rgba(94,103,158,.05)),rgba(38,44,82,.3)"
    }}>
          <div className="flex justify-between text-normal gap-1">
            <span className="text-secondary-font-color">{L("bets", {})}</span>
            <span className="text-white font-normal">
              {count.toLocaleString(locale)}
            </span>
          </div>

          {rtp ? <>
              <hr className="my-2.5 !block bg-white opacity-5" />

              <div className="flex justify-between text-normal gap-1">
                <span className="text-secondary-font-color">RTP</span>
                <span className="text-white font-normal">{rtp}%</span>
              </div>
            </> : null}
        </div>}
    </>;
};
export default function GameDescription({
  gameDetails,
  gameImage,
  meta,
  mobileSlotDisplay
}) {
  const isMobile = useMobile({
    breakPointThree: 600
  } as any);
  const topwins = meta?.statistics?.top_wins;
  const luckiestwins = meta?.statistics?.luckiest_wins;
  const token = useToken();
  const defaultTab = !token || token === "" ? TABS.description : topwins?.length ? TABS.top_wins : luckiestwins?.length ? TABS.luckiest_wins : TABS.description;
  const [gameDescriptionType, setGameDescriptionType] = useState(defaultTab);
  let gameDescriptionContent = gameDetails?.content;
  if (typeof window !== "undefined") {
    const purify = DOMPurify(window);
    purify.setConfig({
      FORBID_TAGS: ["colgroup"]
    });

    // Sanitize the HTML
    gameDescriptionContent = filterTable(purify.sanitize(gameDetails?.content));
  }
  const L = useLanguage(["common", "UserDisplay"]);
  const [body1, body2] = useMemo(() => [topwins?.map((item, i) => <BodyItem {...item} p={i + 1} key={i} />), luckiestwins?.map((item, i) => <BodyItem {...item} p={i + 1} key={i} />)], [topwins, luckiestwins]);
  const WIDTH = `calc((100% - ${POSITION_WIDTH}px) / 5)`;
  const displayMobileSlotMenu = useMemo(() => !!mobileSlotDisplay ? <div className={st["mobile-details-display"]}>{mobileSlotDisplay}</div> : null, [mobileSlotDisplay]);
  return displayMobileSlotMenu || gameDetails ? <div {...classnames(st, "game-description", isMobile, !!mobileSlotDisplay && "game-description__slot-mobile-display")} data-sentry-component="GameDescription" data-sentry-source-file="GameDescription.tsx">
      {gameDetails ? <>
          <div {...classnames(st, "game-image-container")}>
            <div className={st["game-image"]}>
              {gameImage && <Image alt="game image" src={gameImage} sizes="100vw" width={140} height={185} style={{
            width: "100%",
            height: "auto"
          }} />}
            </div>

            <div className={st["meta-container"]}>
              {meta?.title && <div {...classnames(st, "count")}>
                  <hr style={{
              marginTop: 24,
              marginBottom: 18
            }} />
                  <GameNameCount meta={meta} displayMobileSlotMenu={displayMobileSlotMenu} gameDetails={gameDetails} />
                </div>}

              {isMobile !== "mobile-three" && displayMobileSlotMenu}
            </div>
          </div>
          <div {...classnames(st, "game-description-bottom-container"
      // isMobile == "mobile-two" && "mobile-version",
      // isMobile == "mobile-three" && "mobile-version",
      )}>
            {isMobile === "mobile-three" && displayMobileSlotMenu}
            {/* {displayMobileSlotMenu} */}
            {/* {gameNameCount()} */}
            {!displayMobileSlotMenu && <h1>{gameDetails?.heading}</h1>}
            {gameDetails?.content || gameDetails?.content_2 ? <div className={cn(st["game-description-bottom-buttons"], "[@media(max-width:999px)]:mt-[30px]")}>
                {body1?.length ? <Button method={() => {
            setGameDescriptionType(TABS.top_wins);
          }} text={L("big_wins", {})} classType={["tab", "live-tab"]} active={gameDescriptionType === TABS.top_wins} /> : null}

                {body2?.length ? <Button method={() => {
            setGameDescriptionType(TABS.luckiest_wins);
          }} text={L("lucky_wins", {})} classType={["tab", "live-tab"]} active={gameDescriptionType === TABS.luckiest_wins} /> : null}

                {gameDetails?.content_2 && <Button method={() => {
            setGameDescriptionType(TABS.instructions);
          }} text={"How to Play"} classType={["tab", "live-tab"]} active={gameDescriptionType === TABS.instructions} />}

                {gameDetails?.content && <Button method={() => {
            setGameDescriptionType(TABS.description);
          }} text={L("description", {})} classType={["tab", "live-tab"]} active={gameDescriptionType === TABS.description} />}
              </div> : null}
            <Table width={WIDTH} hidden={gameDescriptionType !== TABS.top_wins || !body1?.length} body={body1} head={<Head />} />
            <Table width={WIDTH} hidden={gameDescriptionType !== TABS.luckiest_wins || !body2?.length} body={body2} head={<Head />} />
            <div data-hidden={gameDescriptionType !== TABS.description || !gameDetails?.content} dangerouslySetInnerHTML={{
          __html: gameDescriptionContent
        }} className={st["game-content"]} />
            <div data-hidden={gameDescriptionType !== TABS.instructions || !gameDetails?.content_2} dangerouslySetInnerHTML={{
          __html: gameDetails?.content_2
        }} className={st["game-content"]} />
          </div>
        </> : displayMobileSlotMenu ? displayMobileSlotMenu : null}
    </div> : null;
}